@import "./var.scss";
@font-face {
  font-family: "Arboria";
  src: url("https://use.typekit.net/xiv8sym.css");
}
body {
  font-family: "Arboria", sans-serif;
}
div {
  font-family: "Arboria", sans-serif;
}

.App {
  text-align: center;
}

.logo {
  height: 40px;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.active {
  color: $white !important;
  background-color: $orange;
  padding: 0.5rem 1rem;
  border-radius: 3px;

  @media (max-width: $mobile) {
    color: $orange !important;
    border-bottom: 4px solid;
    padding: 0.75rem !important;
    background-color: transparent;
    border-radius: 0;
  }
}
.bg {
  background: #181818;
  @media (max-width: $mobile) {
    background-position: 40%;
  }
}

.tb {
  min-height: 500px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: $mobile) {
    min-height: 370px !important;
  }
}

h1 {
  letter-spacing: 1rem;

  @media (max-width: $mobile) {
    letter-spacing: 0.5rem;
  }
}